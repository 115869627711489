'use client'

import { SignIn } from '@clerk/nextjs'
import { dark } from '@clerk/themes'
import { useTheme } from 'next-themes'

export function SignInClient({ redirectUrl }: { redirectUrl: string }) {
  const { theme } = useTheme()

  return (
    <div className="flex min-h-[calc(100vh-4rem)] flex-col items-center justify-center">
      <SignIn
        fallbackRedirectUrl={redirectUrl || '/'}
        forceRedirectUrl={redirectUrl || '/'}
        appearance={{
          baseTheme: theme === 'dark' ? dark : undefined,
          elements: {
            formField__emailAddress: {
              label: 'Email Address',
              placeholder: 'Email Address',
              backgroundColor: 'red'
            },
            menuItem__emailAddresses: {
              backgroundColor: 'red'
            },
            menuList__emailAddresses: {
              backgroundColor: 'red'
            },
            formFieldInput__emailAddress: {
              backgroundColor: 'red'
            },
            formFieldRow__emailAddress: {
              backgroundColor: 'red'
            },
            formFieldAction__emailAddress: {
              backgroundColor: 'red'
            },
            menuButton__emailAddresses: {
              backgroundColor: 'red'
            },
            formFieldLabel__emailAddress: {
              backgroundColor: 'red'
            },
            formField__emailAddress__error: {
              backgroundColor: 'red'
            },
            formFieldInput: {
              backgroundColor: 'red'
            },
            formFieldInput__identifier: {
              backgroundColor: 'red'
            },
            input: {
              backgroundColor: 'red'
            }
            /* Not working, why? */
          }
        }}
      />
    </div>
  )
}
